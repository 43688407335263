import { Box, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { aboutUsData } from "../../util/data";

export default function AboutUs() {
  return (
    <Box
      bg="brand.white"
      id="about_us"
      py="10"
      px={{ base: "4", md: "8", xl: "16" }}
    >
      <Text
        fontSize="18px"
        fontWeight="600"
        color="primary.200"
        textAlign="center"
      >
        ABOUT US
      </Text>
      <Flex
        flexDirection={{ base: "column-reverse", lg: "row" }}
        justifyContent="space-around"
        mt="4"
      >
        <Flex
          direction="column"
          justifyContent="space-between"
          mt={{ base: "6", md: "0" }}
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          {aboutUsData.map((ele, index) => {
            return (
              <Flex p="3" alignItems="center" key={`about_${index}`}>
                <Icon as={ele.icon} height="60px" width="60px" />{" "}
                <Text
                  ml="6"
                  color="primary.one"
                  fontSize={{ base: "16px", xl: "20px" }}
                >
                  {ele.text}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        <VStack
          alignSelf="baseline"
          textAlign="left"
          alignItems="flex-start"
          maxW={{ base: "100%", lg: "600px" }}
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <Text
            color="primary.200"
            fontSize={{ base: "20px", md: "25px", xl: "30px" }}
            fontWeight={800}
          >
            Prompt Visa Service PVT Ltd
          </Text>
          <Text color="primary.200" fontSize="20px">
            Your Perfect Visa Partner !
          </Text>
          <Text color="brand.gray" fontSize={{ base: "16px", xl: "20px" }}>
            Prompt visa services private limited is a complete visa and
            immigration services management company based in Delhi. It was
            founded by Hk shah who having 30 years of in - depth experience to
            handle visa process for india’s leading tours operators, TMC and
            many big corporate. We are expert in providing all types of visa
            assistance offline, online and E-visa.
          </Text>
          <Text color="brand.gray" fontSize={{ base: "16px", xl: "20px" }}>
            We have a dependable and professional team who having good knowledge
            and experience in visa documentation and regulations for all
            countries. Our expert and experience team assist you in completing
            your visa documentation on phone to save time and timely submission
            with correct documents
          </Text>
          <Text color="brand.gray" fontSize={{ base: "16px", xl: "20px" }}>
            We always give right information, suggestions and advise to our
            customers to maintain honesty and faith. We are very professional to
            work and always understand our clients business need. We respect our
            customer and always believe in business ethics and honesty. We lead
            by example in the way we do business with integrity, honestly,
            commitment, and transparency in billing, security & data protection.
            Prompt visa services is committed towards provide reliable, accurate
            and quality customer care with empathy to our customer.
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
}
