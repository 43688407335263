import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IoMdPerson } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
const ContactUs = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bg="brand.white"
      p="6"
      id="contact_us"
    >
      <Text fontSize="18px" fontWeight="600" color="primary.200">
        CONTACT US
      </Text>
      <Text
        fontSize={{ base: "35px", md: "40", lg: "45px" }}
        fontWeight="800"
        color="primary.black"
        pt="30px"
        pb="3rem"
      >
        Feel Free To Reach Out
      </Text>

      <Flex direction={{ base: "column", md: "row" }}>
        <Flex direction="column" px="4" justifyContent="space-between">
          <Flex alignItems="center" mb="4">
            <Icon as={IoMdPerson} w="24px" h="24px" color="primary.200" />
            <Text fontSize="20px" ml="3">
              HK Shah
            </Text>
          </Flex>
          <Flex alignItems="center" mb="4">
            <Icon as={HiOutlineMail} w="24px" h="24px" color="primary.200" />
            <Text fontSize="20px" ml="3">
              hkshahcfo007@gmail.com
            </Text>
          </Flex>
          <Flex alignItems="center" mb="4">
            <Icon as={FiPhoneCall} w="24px" h="24px" color="primary.200" />
            <Text fontSize="20px" ml="3">
              +91 9818424501
            </Text>
          </Flex>

          <Flex alignItems="center" mb="4">
            <Icon
              as={MdOutlineLocationOn}
              w="24px"
              h="24px"
              alignSelf="baseline"
              color="primary.200"
            />
            <Flex direction="column" px="4">
              <Text fontSize="20px">
                E-131, First Floor Ganapati Bhawan, Mohammad Pur
              </Text>
              <Text fontSize="20px">
                Near Bhikaji Cama Place, New Delhi 110066
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex alignItems="baseline" mt={2}>
        <Icon as={BsInfoCircle} pt={1} mr={1} color="primary.200" />
        <Box>
          <Text fontSize="14px">
            The Corporate Identification Number (CIN) of Prompt Visa Services
            Private Limited is U63030DL2022PTC398560.
          </Text>
          <Text fontSize="14px">
            {" "}
            The registered office of Prompt Visa Services Private Limited is at
            PLOT NO 209/113 & 114 SOUTH EXTENTION-II,
          </Text>{" "}
          <Text fontSize="14px"> South Delhi, Delhi.</Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ContactUs;
