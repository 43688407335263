import AOS from "aos";
import "aos/dist/aos.css";
import CustomRoutes from "./routes";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import store from "./store/store";
import "./app.css"
import { useEffect } from "react";
function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <CustomRoutes />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
