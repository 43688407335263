
import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Help from '../components/Help/Help.jsx';
import Hero from '../components/Hero/Hero';
import Process from '../components/OurProcess/Process.jsx';
import Services from '../components/OurServices/Services.jsx';
import AboutUs from '../components/AboutUs/AboutUs.js';
import ContactUs from '../components/ContactUs/ContactUs';
import Partners from '../components/Partners/Partners';

function Home() {
  return (
    <>
    <Header />
    <Hero />
    <AboutUs />
    <Process />
    <Services />
    <Partners />
    <Help />
    <ContactUs />
    <Footer />
    </>
  )
}

export default Home;