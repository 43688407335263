import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Marquee from "react-fast-marquee";
import YatraIcon from "../../Assets/img/Yatra_logo.png";
import CosmoIcon from "../../Assets/img/cosmo.png";
import BeGlobalIcon from "../../Assets/img/beglobal.png";
import BedigitekIcon from "../../Assets/img/bedigitek.png";
import EraavacationIcon from "../../Assets/img/eraavacations.png";
import ATPIIcon from "../../Assets/img/atpi_logo.jpg";

import HideSignIcon from "../../Assets/img/HIDESIGN_LOGO.jpg";


const Partners = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bg="brand.white"
      px="6"
      py="8"
      borderBottom="2px"
      borderBottomColor="gray.200"
    >
      <Text
        fontSize={{ base: "35px", md: "40", lg: "45px" }}
        fontWeight="800"
        color="primary.black"
        pt="30px"
        pb="3rem"
      >
        OUR KEY CUSTOMERS
      </Text>
      <Marquee
        gradient={true}
        loop={0}
        gradientColor={[248, 251, 253]}
        gradientWidth="500"
        speed={40}
        delay={0}
      >
        <Image mx="10" height={{base: "40px", md:"50px"}} src={CosmoIcon} alt="cosmo" />
        <Image mx="10" height={{base: "40px", md:"50px"}} src={BeGlobalIcon} alt="Be global" />
        <Image mx="10" height={{base: "40px", md:"50px"}} src={YatraIcon} alt="yarta" />
        <Image mx="10" height={{base: "40px", md:"50px"}} src={ATPIIcon} alt="ATPI" />
        <Image mx="10" height={{base: "40px", md:"50px"}} src={EraavacationIcon} alt="Eraa vacation" />
        <Image mx="10" height={{base: "40px", md:"50px"}} src={BedigitekIcon} alt="Be digital" />
        <Image mx="10" height={{base: "40px", md:"50px"}} src={HideSignIcon} alt="HideSign" />
        
      </Marquee>
    </Flex>
  );
};

export default Partners;
