import { Button, Flex, Icon, Text, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";

const VisaFormsDownloader = () => {
  const [selectedVisa, setSelectedVisa] = useState({ value: "", label: "" });
  const options = [
    {
      label: "Sweden",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/21b75a8eb9d70bdb35ea23f60bfe0d6b",
    },
    {
      label: "Turkey",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/c37dcb9987b75a99fb62d2eed1c3fb36",
    },
    {
      label: "Greece",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/c5997a559099035c37f2da9281986a9f",
    },
    {
      label: "France",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/453872a416ce9feae2297981dfad4264",
    },
    {
      label: "Portugal",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/b4ecc09050720adfd1d304c0e9faba6c",
    },
    {
      label: "Malta",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/d564f1d00cce2f2d359c4defe2b83054",
    },
    {
      label: "Japan",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/1c10b48221cc4cf90917f72a22c02b32",
    },
    {
      label: "Switzerland",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/95a88bb50cbdea375d8dcbd49e455848",
    },
    {
      label: "Spain",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/af1f7a3fb84fe3ff816887cef144b0d9",
    },
    {
      label: "Philippines",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/0279c8e4a6d8fd25709acded7287cb20",
    },
    {
      label: "Jordan",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/10ab5c4d5f6429a4e3acb7331c1b55cc",
    },
    {
      label: "Singapore",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/fe5c83a615928233accd6cfa51bc191c",
    },
    {
      label: "Germany",
      value:
        "https://asset.cloudinary.com/dqzkbxkyk/4167b772b3658e0d36190bf3b2bb81a5",
    },
  ];
  const downloadPDF = () => {
    let alink = document.createElement("a");
    alink.href = selectedVisa.value;
    alink.target = "_blank";
    alink.download = `${selectedVisa.label}-visa-form.pdf`;
    alink.click();
    alink.remove();
  };
  return (
    <Flex
      pt="10"
      pb="10"
      px={{ base: "6", md: "10", xl: "16" }}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      data-aos="fade-right"
      data-aos-duration="2000"
    >
      <Text
        textAlign="center"
        color="primary.100"
        fontSize="18px"
        fontWeight={600}
        mb="10"
      >
        DOWNLOAD VISA FORM
      </Text>

      <Flex
        w={{ base: "sm", md: "2xl" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Select
          maxW="sm"
          placeholder="Select country..."
          value={selectedVisa.value}
          onChange={(e) => {
            setSelectedVisa({
              label: options.find((opt) => opt.value === e.target?.value).label,
              value: e.target?.value,
            });
          }}
        >
          {options.map((opt, index) => (
            <option key={index + "visa_form"} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
        <Button
          px="6"
          py="5"
          mr="1"
          mt={{ base: "5", md: "0" }}
          fontSize="20px"
          color="primary.200"
          onClick={() => downloadPDF()}
          disabled={!selectedVisa.value}
        >
          <Icon as={FaDownload} mr="2" h="18px" /> Download
        </Button>
      </Flex>
    </Flex>
  );
};

export default VisaFormsDownloader;
