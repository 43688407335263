import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  Stack,
  Icon,
} from "@chakra-ui/react";

import { AiFillCloseCircle } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { ReactComponent as Logo } from "../../Assets/icons/brandlogo.svg";
const Links = [
  { title: "HOME", href: "#home" },
  { title: "ABOUT US", href: "#about_us" },
  { title: "SERVICES", href: "#sevices_section" },
  { title: "HELP", href: "#help_section" },
  { title: "CONTACT US", href: "#contact_us" },
];

const NavLink = ({ children, href, onClose }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    color="brand.black"
    fontSize="20px"
    _hover={{
      textDecoration: "none",
      color: "primary.100",
    }}
    border="none"
    href={href}
    onClick={onClose}
  >
    {children}
  </Link>
);

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg="brand.white"
      px={{base:"4", md:"6", lg:"10"}}
      py="1"
      transition="all 2s ease-in ease-out"
      justifyContent="center"
      position="fixed"
      top="0"
      right="0"
      width="full"
      zIndex="1"
    >
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={
            isOpen ? (
              <AiFillCloseCircle fontSize="22px" fill="#AB4A9B" />
            ) : (
              <FiMenu fontSize="22px" fill="#AB4A9B" />
            )
          }
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          background="transparent !important"
          boxShadow="none !important"
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"} justifyContent="center">
          <Icon as={Logo} w={{base:"4rem", md:"5rem", lg:"6rem"}} h={{base:"2rem", md:"3rem", lg:"4rem"}} />
          <HStack
            ml="4"
            as={"nav"}
            spacing={4}
            height="full"
            display={{ base: "none", md: "flex" }}
          >
            {Links.map((link) => (
              <NavLink key={link.title} href={link.href} >
                {link.title}
              </NavLink>
            ))}
          </HStack>
        </HStack>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }} transition="all 2s">
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link.title} href={link.href} onClose={onClose}>
                {link.title}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
