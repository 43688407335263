import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools";
//Extend the theme to include custom colors, fonts, etc
const colors = {
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
      negative: "red",
      white: "#FFFFFF",
      black: "#000000",
      gray: "#5A5E65"
    },
    primary: {
      100: "#5A3795",
      200: "#AB4A9B"
    }
  }

  const breakpoints = createBreakpoints({
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  });
  
  export const theme = extendTheme({ colors, breakpoints })