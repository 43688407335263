import { ReactComponent as StepOne } from "../Assets/icons/step-one.svg";
import { ReactComponent as StepTwo } from "../Assets/icons/step-two.svg";
import { ReactComponent as StepThree } from "../Assets/icons/step-three.svg";
import { ReactComponent as StepFour } from "../Assets/icons/step-four.svg";
import {MdFamilyRestroom, MdOutlineBusinessCenter, MdOutlineGroupAdd} from "react-icons/md";
import {BsPeople, BsPersonBadge} from "react-icons/bs";
import {FaBookReader, FaCalendarAlt, FaHandsHelping, FaPassport} from "react-icons/fa";
import {AiFillFileAdd, AiOutlineForm} from "react-icons/ai";
import {ReactComponent as FeatOne} from "../Assets/icons/Feature-one.svg";
import {ReactComponent as FeatTwo} from "../Assets/icons/Feature-two.svg";
import {ReactComponent as FeatThree} from "../Assets/icons/Feature-three.svg";
import {ReactComponent as FeatFour} from "../Assets/icons/Feature-Four.svg";
import {ReactComponent as AboutUsOne} from "../Assets/icons/aboutus_one.svg";
import {ReactComponent as AboutUsTwo} from "../Assets/icons/aboutus_two.svg";
import {ReactComponent as AboutUsThree} from "../Assets/icons/aboutus_three.svg";
import {ReactComponent as AboutUsFour} from "../Assets/icons/aboutus_four.svg";


// import {ReactComponent as VisaIcon} from "../Assets/icons/visa_icon.svg";


export const processStep = [
    {text: "Fill In All The Required Form", icon: StepOne},
    {text: "Submit All Your Attested", icon: StepTwo},
    {text: "Make Payment For Your Visa", icon: StepThree},
    {text: "Ready To Receive Your Visa", icon: StepFour}
];

export const servicesData = [
    {name: "Business Visa", icon: MdOutlineBusinessCenter},
    {name: "Tourist Visa", icon: BsPeople},
    {name: "Employemnt Visa", icon: BsPersonBadge},
    {name: "Student Visa", icon: FaBookReader},
    {name: "Family Visa", icon: MdFamilyRestroom},
    {name: "Online Application E-Visa", icon: FaPassport},
    {name: "VFS & Consulate Meet & Assist", icon: FaHandsHelping},
    {name: "Visa Appointment", icon: FaCalendarAlt},
    {name: "Attestation, MEA & Apostille", icon: AiFillFileAdd},
    {name: "Passport Assistance", icon: FaPassport},
    {name: "Privilege Submission", icon: AiOutlineForm},
    {name: "PR Assistance", icon: MdOutlineGroupAdd},
];

export const featuerData = [
    {text:"Fast Visa Processing", icon: FeatOne},
    {text:"24 Hours Online Response", icon: FeatTwo},
    {text:"Provide The Best Guidance", icon: FeatThree},
    {text:"Legal Immigration Success", icon: FeatFour}
];

export const detailProcessData = [
    {text: "Document Collection"},
    {text: "Pending additional documents"},
    {text: "QC on documentation"},
    {text: "QC on Visa granted"},
    {text: "Scanning & Courier Dispatch"},
    {text: "Passport shipment & delivery"},
    {text: "Submission at Consulate/ VFS "},
    {text: "Safe Custody"},
    {text: "E-mail updates"},
    {text: "Airport Meet & Assist"},
];

export const aboutUsData = [
    {icon: AboutUsOne, text: "High standards of business ethics with 30+ Years of Experience" },
    {icon: AboutUsTwo, text: "Integrity" },
    {icon: AboutUsThree, text: "Domain understanding & consulate relationships*" },
    {icon: AboutUsFour, text: "Pan-India coverage & service delivery" },
]
