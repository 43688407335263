import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { featuerData } from "../../util/data";

const Help = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bg="brand.white"
      p="6"
      id="help_section"
      data-aos="zoom-out-down"
      data-aos-duration="1500"
    >
      <Text fontSize="18px" fontWeight="600" color="primary.200">
        WHY CHOOSE US
      </Text>
      <Text
        fontSize={{ base: "35px", md: "40", lg: "45px" }}
        fontWeight="800"
        color="primary.black"
        pt="30px"
        pb="3rem"
      >
        Many Benefits and Fast Process
      </Text>

      <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
        {featuerData.map((ele, index) => {
          return (
            <Flex
              key={`help_${index}`}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRight={{
                base: "none",
                lg:
                  index + 1 !== featuerData.length
                    ? "1px solid #CAC2D6"
                    : "none",
              }}
              width="340px"
              mb="8"
              textAlign="center"
            >
              <Icon as={ele.icon} width="87px" height="87px" />
              <Text
                fontSize="25px"
                fontWeight="700"
                color="primary.black"
                pt="6"
                px="3"
              >
                {ele.text}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default Help;
