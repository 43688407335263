import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import HeroImage from "../../Assets/img/hero.png";

const middleContentStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
};
function Hero() {
  return (
    <Box position="relative" id="home" mt="72px">
      <Image src={HeroImage} alt="propmt-visa hero image" minW="full" h={{"xs": 'full',md:'full',  lg:"100vh"}} />
      <Box sx={middleContentStyles} >
        <Text fontSize={{xs:"40px",sm:"40px", md:"44px", lg:"65px", xl:"70px"}} textAlign="center" color="brand.white" fontWeight="800" data-aos="flip-up" data-aos-duration="2500">
         Your Perfect Visa Partner!
        </Text>{" "}
      </Box>
    </Box>
  );
}

export default Hero;
