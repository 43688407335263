import {
    Box,
    Text,
    Flex,
    useColorModeValue,
    Icon,
  } from '@chakra-ui/react';

  import {ReactComponent as Logo} from '../../Assets/icons/brandlogo.svg';
  
  // const ListHeader = ({ children }) => {
  //   return (
  //     <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
  //       {children}
  //     </Text>
  //   );
  // };
  
  export default function Footer({bgColor = "brand.white", txtColor="brand.primary"}) {
    return (
      <Box
        bg="bgColor"
        color={useColorModeValue('gray.700', 'gray.200')}
        py={4}
        >
        {/* <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
            <Stack align={'flex-start'}>
              <ListHeader>Product</ListHeader>
              <Link href={'#'}>Overview</Link>
              <Stack direction={'row'} align={'center'} spacing={2}>
                <Link href={'#'}>Features</Link>
                <Tag
                  size={'sm'}
                  bg={useColorModeValue('green.300', 'green.800')}
                  ml={2}
                  color={'white'}>
                  New
                </Tag>
              </Stack>
              <Link href={'#'}>Tutorials</Link>
              <Link href={'#'}>Pricing</Link>
              <Link href={'#'}>Releases</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Company</ListHeader>
              <Link href={'#'}>About Us</Link>
              <Link href={'#'}>Press</Link>
              <Link href={'#'}>Careers</Link>
              <Link href={'#'}>Contact Us</Link>
              <Link href={'#'}>Partners</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Legal</ListHeader>
              <Link href={'#'}>Cookies Policy</Link>
              <Link href={'#'}>Privacy Policy</Link>
              <Link href={'#'}>Terms of Service</Link>
              <Link href={'#'}>Law Enforcement</Link>
              <Link href={'#'}>Status</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Follow Us</ListHeader>
              <Link href={'#'}>Facebook</Link>
              <Link href={'#'}>Twitter</Link>
              <Link href={'#'}>Dribbble</Link>
              <Link href={'#'}>Instagram</Link>
              <Link href={'#'}>LinkedIn</Link>
            </Stack>
          </SimpleGrid>
        </Container> */}
        <Box>
          <Flex
            align={'center'}
            _before={{
              content: '""',
              borderBottom: '1.5px solid',
              borderColor: useColorModeValue('gray.200', 'gray.700'),
              flexGrow: 1,
              mr: 8,
            }}
            _after={{
              content: '""',
              borderBottom: '1.5px solid',
              borderColor: useColorModeValue('gray.200', 'gray.700'),
              flexGrow: 1,
              ml: 8,
            }}>
            <Icon as={Logo} width="6em" height="4em"/>
          </Flex>
          <Text pt={6} color={txtColor} fontSize='md' fontWeight="bold" textAlign={'center'}>
            © 2022 Prompt Visa. All rights reserved
          </Text>
        </Box>
      </Box>
    );
  }