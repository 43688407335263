import { Flex, Icon, Link, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { detailProcessData, processStep } from "../../util/data";
import VisaFormsDownloader from "../common/VisaFormsDownloader";

const Process = () => {
  return (
    <Flex
      pt="20"
      px={{ base: "6", md: "10", xl: "16" }}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Text
        textAlign="center"
        color="primary.100"
        fontSize="18px"
        fontWeight={600}
      >
        HOW WE WORK
      </Text>
      <Text
        textAlign="center"
        fontSize={{ base: "25px", md: "35px", xl: "45px" }}
        fontWeight={800}
        py="3"
      >
        Bold Approaches Expert Counsel Results That Matter
      </Text>
      <Flex
        mt="58px"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        flexFlow="wrap"
        w="full"
      >
        {processStep.map((ele, index) => {
          return (
            <Flex
              background="brand.white"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              w="300px"
              h="300px"
              px="42px"
              position="relative"
              borderRadius="10px"
              key={`${index}_process`}
              sx={{
                WebkitBoxShadow: "0px 2px 24px -10px rgba(0,0,0,0.75)",
                MozBoxShadow: "0px 2px 24px -10px rgba(0,0,0,0.75)",
                BoxShadow: "0px 2px 24px -10px rgba(0,0,0,0.75)",
              }}
              mb="20"
              data-aos="flip-right"
              data-aos-duration="1500"
            >
              <Icon as={ele.icon} w="51px" h="61px" />
              <Text fontSize="25px" textAlign="center" fontWeight="700" mt="4">
                {ele.text}
              </Text>
              <Flex
                position="absolute"
                bottom="-30px"
                h="63px"
                w="63px"
                alignItems="center"
                justifyContent="center"
                fontSize="25px"
                fontWeight="700"
                color="brand.white"
                borderRadius="50%"
                background="primary.200"
              >
                0{index + 1}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <VisaFormsDownloader />

      <Text
        textAlign="center"
        fontSize={{ base: "25px", md: "35px", xl: "45px" }}
        fontWeight={800}
        py="3"
      >
        Detailed Process
      </Text>
      <SimpleGrid
        mt="58px"
        columns={{ base: 1, sm: 3, md: 4, xl: 5 }}
        spacing={4}
      >
        {detailProcessData.map((ele, index) => {
          return (
            <Flex
              background="brand.white"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              w="200px"
              h="200px"
              px="42px"
              position="relative"
              borderRadius="10px"
              bg="primary.200"
              key={`${index}_process`}
              color="#f0f2f5f5"
              fontWeight="700"
              sx={{
                _hover: {
                  WebkitBoxShadow: "0px 4px 28px -8px rgba(0,0,0,0.75)",
                  MozBoxShadow: "0px 4px 28px -10px rgba(0,0,0,0.75)",
                  BoxShadow: "0px 4px 28px -8px rgba(0,0,0,0.75)",
                  color: "brand.white",
                  fontWeight: "800",
                },
                transition: "box-shadow 0.3s ease-in-out",
              }}
              mb="20"
              data-aos="flip-left"
              data-aos-duration="1500"
            >
              <Text fontSize="20px" textAlign="center">
                {ele.text}
              </Text>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default Process;
