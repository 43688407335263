import { Box, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { servicesData } from "../../util/data";

const Services = () => {
  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      background="primary.100"
      px={{ base: "6", md: "10", lg: "12", xl: "16" }}
      py="5rem"
      id="sevices_section"
    >
      <Text fontSize="18px" fontWeight="600" color="brand.white">
        SERVICES
      </Text>
      <Text
        fontSize={{base:"35px", md:"40", lg:"45px"}}
        fontWeight="800"
        color="brand.white"
        pt="30px"
        pb="4rem"
      >
        We Provide Assistance For Following Services
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, xl: 4 }} spacing={4}>
        {servicesData.map((ele, index) => {
          return (
            <Flex
                p="4"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
              bg="brand.white"
              height="200px"
              width="200px"
              key={`service_${index}`}
              data-aos="zoom-in-up"
              data-aos-duration="1500"
            >
              <Icon as={ele.icon} width="45px" height="45px" color="primary.200" />
              <Text fontSize="20px" fontWeight="800" pt="1.5rem">
                {ele.name}
              </Text>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default Services;
